import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";

import TextField from "../../../../components/TextField";
import Button from "../../../../components/Button2";
import Items from "./Items";
import { ActivityReservationType } from "../ActionButtons/types";
import Answers from "./Answers";
import ConfirmReservationButton from "../ActionButtons/ConfirmReservationButton";
import RejectReservationButton from "../ActionButtons/RejectReservationButton";
import CancelReservationButton from "../ActionButtons/CancelReservationButton";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useNotifications } from "../../../../components/Notification";
import { ConfirmationSelect } from "../ConfirmationSelect";
import { ReservationStatus } from "../../../../types";

function ActivityReservation(props: {
  bookingId: string;
  data: ActivityReservationType;
  refetch: () => Promise<void>;
  paymentStatus: string;
  canEditItems: boolean;
}) {
  const reservation = props.data;
  const paymentStatus = props.paymentStatus;

  const [editActivityItems] = useMutation(EDIT_ACTIVITY_ITEMS);

  const [activityItems, setActivityItems] = useState(reservation.items);

  const [reservationStatus, setReservationStatus] = useState<ReservationStatus>(
    reservation.status as ReservationStatus
  );

  const { showNotification } = useNotifications();

  const handleEditBookingActivity = useCallback(async () => {
    for (const item of activityItems) {
      const result = await editActivityItems({
        variables: {
          activityReservationItemId: item.id,
          activityReservationId: reservation.id,
          quantity: item.quantity,
          reservationStatus,
        },
      });
      const { success, error } = result.data.editBookingActivityItem;
      if (success) {
        showNotification({
          message: `Booking was edited`,
          severity: "success",
        });
        await props.refetch();
      } else {
        showNotification({
          message: `Editing booking failed! ${error}`,
          severity: "error",
        });
      }
    }
  }, [
    activityItems,
    reservationStatus,
    editActivityItems,
    props,
    reservation.id,
    showNotification,
  ]);

  let actionButtons;

  if (paymentStatus === "PAID" && reservation.status === "NOT_YET_RESERVED") {
    actionButtons = (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ConfirmReservationButton reservation={reservation} />
        </Grid>
        <Grid item xs={6}>
          <RejectReservationButton reservation={reservation} />
        </Grid>
      </Grid>
    );
  } else if (reservation.status === "RESERVED") {
    actionButtons = (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CancelReservationButton reservation={reservation} />
        </Grid>
      </Grid>
    );
  } else {
    actionButtons = <></>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Reservation Info" action={actionButtons} />
          <Divider />
          <CardContent>
            <TextField
              readOnly={true}
              label={"Reservation ID"}
              value={reservation.id}
            />
            <ConfirmationSelect
              label={"Reservation Status"}
              value={reservationStatus}
              setValue={setReservationStatus}
              values={Object.values(ReservationStatus)}
              fontColor={ReservationStatusColor[reservationStatus]}
            />
            {(reservationStatus === ReservationStatus.RESERVED ||
              reservation.reservedAt) && (
              <TextField
                readOnly={true}
                label={"Reserved At"}
                value={reservation.reservedAt || "determined on save"}
              />
            )}
            {reservationStatus === ReservationStatus.REJECTED && (
              <TextField
                readOnly={true}
                label={"Rejected At"}
                value={reservation.rejectedAt || "determined on save"}
              />
            )}
            {reservationStatus === ReservationStatus.CANCELLED && (
              <TextField
                readOnly={true}
                label={"Cancelled At"}
                value={reservation.cancelledAt || "determined on save"}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <TextField
              readOnly={true}
              label={"Venue"}
              value={reservation.venue.name}
            />
            <TextField
              readOnly={true}
              label={"Seat Option"}
              value={reservation.seatOption.title}
            />
            {reservation.seatOption.price !== undefined &&
              reservation.seatOption.price > 0 && (
                <TextField
                  readOnly={true}
                  label={"Seat Option Price"}
                  value={reservation.seatOption.price}
                />
              )}
            <TextField
              readOnly={true}
              label={"Datetime"}
              value={reservation.datetime}
            />
            <TextField
              readOnly={true}
              label={"Special Requests"}
              value={reservation.specialRequests || ""}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Items
          items={activityItems}
          setItems={setActivityItems}
          readOnly={!props.canEditItems}
        />
      </Grid>
      {reservation.answers && (
        <Grid item xs={12}>
          <Answers answers={reservation.answers} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Card style={{ padding: "10px" }}>
          <Button onClick={handleEditBookingActivity}>Save</Button>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ActivityReservation;

const ReservationStatusColor: { [key: string]: string } = {
  NOT_YET_RESERVED: "#000000",
  RESERVED: "#4caf50",
  REJECTED: "#f44336",
  CANCELLED: "#9c27b0",
};

const EDIT_ACTIVITY_ITEMS = gql`
  mutation EDIT_ACTIVITY_ITEMS(
    $activityReservationItemId: ID!
    $quantity: Int!
    $activityReservationId: String
    $reservationStatus: ReservationStatus
  ) {
    editBookingActivityItem(
      input: {
        activityReservationItemId: $activityReservationItemId
        quantity: $quantity
        activityReservationId: $activityReservationId
        reservationStatus: $reservationStatus
      }
    ) {
      success
      error
    }
  }
`;
