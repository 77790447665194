import { gql, useMutation } from "@apollo/client";
import { Button, Grid, IconButton, MenuItem, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import PageSection from "../../../components/PageSection";
import TextField from "../../../components/TextField";
import { BankAccountType, Company, Contract, Venue } from "../../../types";
import { useNotifications } from "../../../components/Notification";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";
import { RequiredTextfield } from "./RequiredTextfield";
import ContractList from "./ContractList";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import AddCircle from "@mui/icons-material/AddCircle";
import AutocompleteVenueSelector from "../../../components/AutocompleteVenueSelector";

// immutable true: use bank account data, and it's impossible to remove
// mutable true: use bank account data, but it's possible to disable
// mutable false: do not use bank account data, but it's possible to enable
enum UseBankAccountState {
  immutableTrue,
  mutableTrue,
  mutableFalse,
}

export function CompanyForm({ company, onSaveCallback }: CompanyFormProps) {
  const { showNotification } = useNotifications();
  const [useBankAccount, setUseBankAccount] = useState(
    UseBankAccountState.mutableFalse
  );
  const nameLabel = "Name";
  const recipientLabel = "Recipient";
  const holderLabel = "Holder";
  const typeLabel = "Type";
  const numberLabel = "Number";
  const bankLabel = "Bank";

  const [name, setName] = useState("");
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [createdAt, setCreatedAt] = useState("");
  const [accountRecipient, setAccountRecipient] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [venues, setVenues] = useState<Venue[]>([]);
  const companyId = useMemo(
    () => (company !== undefined ? company.id : uuid()),
    [company]
  );
  const accountId = useMemo(() => company?.bankAccount?.id ?? uuid(), [
    company,
  ]);

  const requiredFields = [
    [nameLabel, name],
    ...(useBankAccount !== UseBankAccountState.mutableFalse
      ? ([
          [recipientLabel, accountRecipient],
          [holderLabel, accountHolder],
          [typeLabel, accountType],
          [numberLabel, accountNumber],
          [bankLabel, bank],
        ] as [string, string][])
      : []),
  ].map(([label, value]) => ({ label, value }));

  const [saveCompany] = useMutation<
    CompanyMutationResult,
    CompanyMutationInput
  >(SAVE_COMPANY_MUTATION, {
    update: () => {
      setContracts((contracts) =>
        contracts.map((contract) => ({
          ...contract,
          url: contract.url.startsWith("tmp/")
            ? contract.url.slice(4)
            : contract.url,
        }))
      );
    },
  });

  const saveVariables = useMemo(() => {
    const variables = {
      input: {
        company: {
          id: companyId,
          name,
          contracts,
        },
        venueIds: venues.map((venue) => venue.id),
      },
    };
    if (useBankAccount !== UseBankAccountState.mutableFalse)
      variables.input = Object.assign({}, variables.input, {
        bankAccount: {
          id: accountId,
          recipient: accountRecipient,
          holder: accountHolder,
          type: accountType,
          number: accountNumber,
          bank,
        },
      });
    return variables;
  }, [
    companyId,
    name,
    contracts,
    accountId,
    accountRecipient,
    accountHolder,
    accountType,
    accountNumber,
    bank,
    venues,
    useBankAccount,
  ]);

  const handleSave = useCallback(() => {
    const missingRequiredField = requiredFields.find(
      ({ value }) => !Boolean(value)
    );

    const duplicateContractName = [
      ...contracts
        .reduce((uniqueNames, contract) => {
          uniqueNames.set(
            contract.name,
            uniqueNames.get(contract.name) !== undefined
          );
          return uniqueNames;
        }, new Map<string, boolean>())
        .values(),
    ].find((isDuplicateName) => isDuplicateName);

    const emptyContractName = contracts.find(
      (contract) => !Boolean(contract.name)
    );

    if (missingRequiredField !== undefined) {
      showNotification({
        message: `Required field ${missingRequiredField.label} must not be empty`,
        severity: "error",
      });
      return;
    }
    if (
      emptyContractName !== undefined ||
      duplicateContractName !== undefined
    ) {
      showNotification({
        message: `Contracts must have a non-empty unique name`,
        severity: "error",
      });
      return;
    }

    saveCompany({
      variables: saveVariables,
    })
      .then((res) => {
        const { error } = res.data!.editCompany;
        if (error) throw error;

        showNotification({
          message: "Saved company data",
          severity: "success",
        });

        if (onSaveCallback) onSaveCallback(companyId);
      })
      .catch((error) => {
        showNotification({
          message: `error: ${error}`,
          severity: "error",
        });
      });
  }, [
    companyId,
    contracts,
    onSaveCallback,
    requiredFields,
    saveCompany,
    showNotification,
    saveVariables,
  ]);

  useEffect(() => {
    if (company === undefined) return;
    const bankAccount = company.bankAccount;
    setName(company.name);
    setCreatedAt(
      format(new Date(Number(company.createdAt)), "yyyy-MM-dd HH:mm:ss")
    );
    const { recipient, holder, type, number, bank } = bankAccount ?? {};
    setContracts(company.contracts);
    setVenues(company.venues);
    setAccountRecipient(recipient ?? "");
    setAccountHolder(holder ?? "");
    setAccountType(type ?? "");
    setAccountNumber(number ?? "");
    setBank(bank ?? "");
    setUseBankAccount(
      [recipient, holder, type, number, bank].every((x) => x !== undefined)
        ? UseBankAccountState.immutableTrue
        : UseBankAccountState.mutableFalse
    );
  }, [company]);

  return (
    <Grid container>
      <Grid item lg={6}>
        <PageSection>
          <Typography variant="h6">Company</Typography>

          <TextField label="ID" value={companyId} />
          <TextField
            label="Created at"
            value={createdAt}
            InputLabelProps={{ shrink: true }}
            placeholder="determined on save"
          />
          <RequiredTextfield
            label={nameLabel}
            value={name}
            setValue={setName}
          />
        </PageSection>
      </Grid>
      <Grid item lg={6}>
        <PageSection>
          <div
            style={{ display: "flex", alignItems: "center", height: "2rem" }}
          >
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Bank Account
            </Typography>
            {useBankAccount !== UseBankAccountState.immutableTrue && (
              <IconButton
                onClick={() =>
                  setUseBankAccount((useBankAccount) =>
                    useBankAccount === UseBankAccountState.mutableFalse
                      ? UseBankAccountState.mutableTrue
                      : UseBankAccountState.mutableFalse
                  )
                }
              >
                {useBankAccount === UseBankAccountState.mutableFalse ? (
                  <AddCircle />
                ) : (
                  <RemoveCircle />
                )}
              </IconButton>
            )}
          </div>
          {useBankAccount !== UseBankAccountState.mutableFalse && (
            <>
              <RequiredTextfield
                label={recipientLabel}
                value={accountRecipient}
                setValue={setAccountRecipient}
              />
              <RequiredTextfield
                label={holderLabel}
                value={accountHolder}
                setValue={setAccountHolder}
              />
              <RequiredTextfield
                select
                label={typeLabel}
                value={accountType}
                setValue={setAccountType}
              >
                {Object.values(BankAccountType).map((accountType) => (
                  <MenuItem key={accountType} value={accountType}>
                    {accountType}
                  </MenuItem>
                ))}
              </RequiredTextfield>

              <RequiredTextfield
                label={numberLabel}
                value={accountNumber}
                setValue={setAccountNumber}
              />
              <RequiredTextfield
                label={bankLabel}
                value={bank}
                setValue={setBank}
              />
            </>
          )}
        </PageSection>
      </Grid>

      <PageSection lg={12}>
        <Typography variant="h6">Contracts</Typography>
        <ContractList
          contracts={contracts}
          setContracts={setContracts}
          companyId={companyId}
        />
      </PageSection>

      <PageSection lg={12}>
        <Typography variant="h6">Venues</Typography>
        <AutocompleteVenueSelector venues={venues} setVenues={setVenues} />
      </PageSection>

      <PageSection lg={12}>
        <Button onClick={handleSave} variant="outlined">
          Save
        </Button>
      </PageSection>
    </Grid>
  );
}

type CompanyMutationInput = {
  input: {
    company: {
      id: string;
      name: string;
      contracts: Contract[];
    };
    bankAccount?: {
      recipient: String;
      holder: String;
      type: String;
      number: String;
      bank: String;
    };
    venueIds: string[];
  };
};

type CompanyMutationResult = {
  editCompany: {
    success: boolean;
    error?: string;
  };
};

const SAVE_COMPANY_MUTATION = gql`
  mutation SaveCompanyMutation($input: EditCompanyInput!) {
    editCompany(input: $input) {
      success
      error
    }
  }
`;

type CompanyFormProps = {
  company?: Company;
  onSaveCallback?: (companyId: string) => void;
};
