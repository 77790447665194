import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import DataTabs from "../../../components/DataTabs";
import Basic from "./Basic";
import ActivityReservations from "./ActivityReservations";
import Products from "./Products";
import QuestionAnswers from "./QuestionAnswers";

function ViewBooking() {
  const { id: bookingId } = useParams<{
    id: string;
    paymentStatus: string;
  }>();
  const { loading, error, data, refetch: refetchBooking } = useQuery(
    GET_BOOKING,
    {
      variables: { id: bookingId },
    }
  );

  if (loading) return <>Loading...</>;
  if (error) return <>Error! {error.message}</>;

  const tabs = [
    {
      title: "Basic",
      content: <Basic booking={data.booking} refetchBooking={refetchBooking} />,
    },
    {
      title: "Activities",
      content: (
        <ActivityReservations
          bookingId={data.booking.id}
          paymentStatus={data.booking.paymentStatus}
          paymentMethod={data.booking.paymentIntent.method}
          refetchBooking={refetchBooking}
        />
      ),
    },
  ];

  if (data.bookingPlanQuestionAnswers.length > 0)
    tabs.push({
      title: "Question Answers",
      content: <QuestionAnswers data={data.bookingPlanQuestionAnswers} />,
    });
  if (data.booking.productOrderId)
    tabs.push({
      title: "Products",
      content: <Products bookingId={bookingId!} />,
    });

  return <DataTabs tabs={tabs} />;
}

export const GET_BOOKING = gql`
  query BookingQuery($id: ID!) {
    booking(id: $id) {
      id
      status
      paymentStatus
      familyName
      givenName
      familyNameFurigana
      givenNameFurigana
      email
      phoneNumber
      reservationDatetime
      createdAt
      confirmedAt
      rejectedAt
      paidAt
      refundedAt
      paymentAmount
      paymentCurrency
      paymentIntentId
      cancelledAt
      cancelledBy
      cancellationReason
      cancellationFee
      noShow
      notes
      plan {
        id
        name
        mainVenue {
          id
          name
          nameFurigana
          description
          phoneNumber
          email
          notificationEmails
          numberOfSeats
          businessHours
          holidays
          smokingAllowance
          acceptedPaymentMethods
          dressCode
          limitedEntryForChildren
          babyChair
          kidChair
          wheelChairAccess
          breastFeedingRoom
          parking
          remarks
          internalRemarks
          postcode
          location {
            address
            googleMapsUrl
            googleMapsEmbedUrl
            directions
            nearestStations
          }
        }
      }
      paymentIntent {
        method
      }
      productOrderId
    }
    bookingPlanQuestionAnswers(bookingId: $id) {
      question
      answer
      imageUrl
    }
  }
`;

export default ViewBooking;
