import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import TextField from "../../../../../components/TextField";

interface ItemProps {
  data: ItemType;
  setQuantity: (quantity: number) => void;
  readOnly?: boolean;
}

function Item({ data, setQuantity, readOnly }: ItemProps) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              readOnly={true}
              label={"Price Type"}
              value={data.priceType}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              readOnly={true}
              label={"Unit type"}
              value={data.unitType}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="text"
              readOnly={readOnly ?? true}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              label={"Quantity"}
              value={data.quantity}
              onChange={(e: any) => {
                const value = Number(e.target.value);
                if (!isNaN(value)) setQuantity(value);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Item;

export type ItemType = {
  id?: number;
  priceType: string;
  unitType: string;
  quantity: number;
  contractedPrice: number;
};
