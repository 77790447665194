import { useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery, ApolloQueryResult } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import TextField from "../../../components/TextField";
import { BookingStatusColor, PaymentStatusColor } from "../helpers";
import CancelBookingButton from "./ActionButtons/CancelBookingButton";
import RefundPaymentButton from "./ActionButtons/RefundPaymentButton";
import ChangeDateTimeButton from "./ActionButtons/ChangeDateTimeButton";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import {
  Booking,
  BookingStatus,
  PaymentStatus,
  ReservationStatus,
} from "../../../types";
import { GET_BOOKING_ACTIVITY_RESERVATIONS } from "./ActivityReservations";
import { ConfirmationSelect } from "./ConfirmationSelect";
import { CircularProgress } from "@mui/material";

export default function Basic(props: {
  booking: Booking;
  refetchBooking: () => Promise<ApolloQueryResult<any>>;
}) {
  const booking = props.booking;
  const noteLength = 1000;

  const { loading, error, data } = useQuery(reservationsQuery, {
    variables: { bookingId: booking.id },
  });

  const { showNotification } = useNotifications();
  const [status, setStatus] = useState<BookingStatus>(booking.status);
  useEffect(() => setStatus(props.booking.status), [props.booking.status]);

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
    booking.paymentStatus
  );
  const [noShow, setNoShow] = useState(booking.noShow);
  const [notes, setNotes] = useState<string>(booking?.notes || "");
  const [email, setEmail] = useState<string>(booking?.email || "");
  const [cancellationReason, setCancellationReason] = useState<string>(
    booking?.cancellationReason || "unknown"
  );
  const [cancellationFee, setCancellationFee] = useState<number>(
    booking?.cancellationFee || 0
  );
  const cancelledBy = useMemo(() => {
    return booking?.cancelledBy || "operator";
  }, [booking]);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    booking?.phoneNumber || ""
  );
  const [charactersLeft, setCharactersLeft] = useState<number>(
    noteLength - notes?.length
  );
  const [editBooking, { loading: saving }] = useMutation(
    EDIT_BOOKING_MUTATION,
    {
      async update() {
        await props.refetchBooking();
      },
    }
  );

  if (loading) return <>Loading...</>;
  if (error) return <>Something went wrong</>;
  const activityReservations = data.bookingActivityReservations.records;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="Booking Info"
            action={
              ([
                ReservationStatus.NOT_YET_RESERVED,
                ReservationStatus.RESERVED,
              ].includes(activityReservations[0].status) ||
                [BookingStatus.CONFIRMED, BookingStatus.CONFIRMED].includes(
                  booking.status
                )) && (
                <CancelBookingButton
                  booking={booking}
                  reservations={activityReservations}
                />
              )
            }
          />
          <Divider />
          <CardContent>
            <TextField readOnly={true} label={"ID"} value={booking.id} />
            <ConfirmationSelect
              label="Status"
              value={status}
              setValue={setStatus}
              values={Object.values(BookingStatus)}
              fontColor={BookingStatusColor[status]}
            />

            <TextField
              readOnly={true}
              label={"Created At"}
              value={booking.createdAt}
            />
            {(status === BookingStatus.CONFIRMED || booking.confirmedAt) && (
              <TextField
                readOnly={true}
                label={"Confirmed At"}
                value={booking.confirmedAt || "determined on save"}
              />
            )}
            {status === BookingStatus.REJECTED && (
              <TextField
                readOnly={true}
                label={"Rejected At"}
                value={booking.rejectedAt || "determined on save"}
              />
            )}
            {status === BookingStatus.CANCELLED && (
              <>
                <TextField
                  readOnly={true}
                  label={"Cancelled At"}
                  value={booking.cancelledAt || "determined on save"}
                />
                <TextField
                  readOnly={true}
                  label={"Cancelled By"}
                  value={booking.cancelledBy || "operator"}
                />
                <TextField
                  label={"Cancellation Reason"}
                  value={cancellationReason}
                  onChange={(e: any) => setCancellationReason(e.target.value)}
                />
                <TextField
                  label={"Cancellation Fee"}
                  value={cancellationFee}
                  onChange={(e: any) => setCancellationFee(e.target.value)}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </>
            )}
            <Checkbox
              style={{ marginTop: "10px" }}
              label="No Show"
              labelPlacement="end"
              checked={noShow}
              onChange={(checked) => setNoShow(checked)}
            />
          </CardContent>
        </Card>
        <Card style={{ marginTop: "24px" }}>
          <CardHeader
            title="Plan"
            action={
              (booking.status === BookingStatus.CONFIRMED ||
                booking.status === BookingStatus.REQUESTED) && (
                <ChangeDateTimeButton
                  bookingId={booking.id}
                  currentReservationDateTime={booking.reservationDatetime}
                />
              )
            }
          />
          <Divider />
          <CardContent>
            <TextField
              readOnly={true}
              label={"Plan Id"}
              value={booking.plan.id}
            />
            <TextField
              readOnly={true}
              label={"Plan Name"}
              value={booking.plan.name}
            />
            <TextField
              readOnly={true}
              label={"Reservation DateTime"}
              value={booking.reservationDatetime}
            />
          </CardContent>
        </Card>
        <Card style={{ marginTop: "24px" }}>
          <CardHeader title="Internal Notes" />
          <Divider />
          <CardContent>
            <TextField
              label={"Note"}
              multiline
              value={notes}
              onChange={(e: any) => {
                setCharactersLeft(noteLength - e.target.value.length);
                setNotes(e.target.value);
              }}
            />
            <small>{charactersLeft} characters left.</small>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Customer" />
          <Divider />
          <CardContent>
            <TextField
              label={"Email"}
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  readOnly={true}
                  label={"Family Name"}
                  value={booking.familyName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  readOnly={true}
                  label={"Given Name"}
                  value={booking.givenName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  readOnly={true}
                  label={"Family Name Furigana"}
                  value={booking.familyNameFurigana}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  readOnly={true}
                  label={"Given Name Furigana"}
                  value={booking.givenNameFurigana}
                />
              </Grid>
            </Grid>
            <TextField
              label={"Phone Number"}
              value={phoneNumber}
              onChange={(e: any) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </CardContent>
        </Card>
        <Card style={{ marginTop: "24px" }}>
          <CardHeader
            title="Payment"
            action={
              booking.paymentStatus === PaymentStatus.PAID &&
              booking.paymentIntent?.method !== "ON_SITE_PAYMENT" && (
                <RefundPaymentButton
                  booking={booking}
                  reservations={activityReservations}
                />
              )
            }
          />
          <Divider />
          <CardContent>
            <>
              <ConfirmationSelect
                label="Payment Status"
                value={paymentStatus}
                setValue={setPaymentStatus}
                values={Object.values(PaymentStatus)}
                fontColor={PaymentStatusColor[paymentStatus]}
              />
              <TextField
                readOnly={true}
                label={"Paid At"}
                value={booking.paidAt || ""}
              />
            </>

            {booking.rejectedAt && (
              <TextField
                readOnly={true}
                label={"Refunded At"}
                value={booking.refundedAt}
              />
            )}
            <TextField
              readOnly={true}
              label={"Payment Amount"}
              value={booking.paymentAmount}
            />
            <TextField
              readOnly={true}
              label={"Payment Currency"}
              value={booking.paymentCurrency}
            />
            <TextField
              readOnly={true}
              label={"Payment method"}
              value={booking.paymentIntent?.method || ""}
            />
            <TextField
              readOnly={true}
              label={"Payment Intent Id"}
              value={booking.paymentIntentId || ""}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Venue" />
          <Divider />
          <CardContent>
            <TextField
              label={"Name"}
              value={booking.plan.mainVenue?.name}
              readOnly
            />
            <TextField
              label={"Postcode"}
              value={booking.plan.mainVenue?.postcode}
              readOnly
            />
            <TextField
              label={"Address"}
              value={booking.plan.mainVenue?.location?.address}
              readOnly
            />
            <TextField
              label={"Phone Number"}
              value={booking.plan.mainVenue?.phoneNumber}
              readOnly
            />
            <TextField
              label={"Email Address"}
              value={booking.plan.mainVenue?.email}
              readOnly
            />
            <TextField
              multiline
              label={"Notification Email Address(es)"}
              value={booking.plan.mainVenue?.notificationEmails}
              readOnly
            />
            <TextField
              multiline
              label={"Internal Remarks"}
              value={booking.plan.mainVenue?.internalRemarks}
              readOnly
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: "10px" }}>
          <Button disabled={saving} onClick={handleEditBooking}>
            {saving ? <CircularProgress size={20} /> : "Save"}
          </Button>
        </Card>
      </Grid>
    </Grid>
  );

  function handleEditBooking() {
    if (notes.length > noteLength) {
      showNotification({
        message: `You cannot enter notes longer than ${noteLength} characters.`,
        severity: "error",
      });
      return;
    }
    editBooking({
      variables: {
        bookingId: booking.id,
        noShow,
        notes,
        email,
        phoneNumber,
        status,
        paymentStatus,
        cancellationFee: Number(cancellationFee),
        cancellationReason,
        cancelledBy,
      },
      refetchQueries: ["BookingActivityReservationsQuery"],
    })
      .then(() => {
        showNotification({
          message: `Booking was edited`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `Editing booking failed! ${err}`,
          severity: "error",
        });
      });
  }
}

const EDIT_BOOKING_MUTATION = gql`
  mutation EditBookingMutation(
    $bookingId: ID!
    $noShow: Boolean!
    $notes: String
    $email: String!
    $phoneNumber: String!
    $status: BookingStatus
    $paymentStatus: PaymentStatus
    $cancellationReason: String
    $cancellationFee: Int
    $cancelledBy: String
  ) {
    editBooking(
      input: {
        bookingId: $bookingId
        noShow: $noShow
        notes: $notes
        email: $email
        phoneNumber: $phoneNumber
        status: $status
        paymentStatus: $paymentStatus
        cancellationReason: $cancellationReason
        cancellationFee: $cancellationFee
        cancelledBy: $cancelledBy
      }
    ) {
      success
    }
  }
`;

const reservationsQuery = GET_BOOKING_ACTIVITY_RESERVATIONS;
