import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../../../components/Button2";
import DataTabs from "../../../components/DataTabs";
import Basic from "./Basic";
import Images from "./Images";
import Products from "./Products";
import Fonts from "./Fonts";
import Stamps from "./Stamps";

function ViewItem() {
  const { id: templateId } = useParams<{
    id: string;
  }>();

  const tabs = [
    { title: "Basic", content: <Basic templateId={templateId!} /> },
    { title: "Item Images", content: <Images /> },
    { title: "Products", content: <Products /> },
    { title: "Fonts", content: <Fonts /> },
    { title: "Stamps", content: <Stamps /> },
  ];

  return (
    <>
      <Button style={{ marginBottom: "15px" }} component={Link} to={`/items`}>
        Back to list
      </Button>
      <DataTabs tabs={tabs} />
    </>
  );
}

export default ViewItem;
