import { Button, Grid } from "@mui/material";
import TemplateCardForm from "./templateCardForm";
import TemplateItemForm, { TemplateGridItemProps } from "./templateItemForm";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuid } from "uuid";
import { PlanTemplateCard } from "../../../../types";
import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  NotificationData,
  useNotifications,
} from "../../../../components/Notification";

type TemplateFormProps = {
  setPlanTemplateCards: (cards: PlanTemplateCard[]) => void;
} & TemplateGridItemProps;

export default function TemplateForm({
  planTemplate,
  setPlanTemplateCards,
  setHowToGive,
  setWhenToGive,
  howToOptions,
  whenToOptions,
  addHowToOption,
  addWhenToOption,
  handleDelete,
}: TemplateFormProps) {
  const { loading, error, refetch } = useQuery(PLAN_TEMPLATE_CARD_QUERY, {
    variables: { planTemplateId: planTemplate.id },
    notifyOnNetworkStatusChange: true,
  });

  const [deletePlanTemplateCard] = useMutation(
    DELETE_PLAN_TEMPLATE_CARD_MUTATION
  );

  const { showNotification } = useNotifications();

  if (loading) return <>Loading...</>;
  if (error)
    return (
      <div onClick={() => refetch()}>Failed to load item cards. Try again?</div>
    );

  return (
    <Grid>
      <TemplateItemForm
        planTemplate={planTemplate}
        setHowToGive={setHowToGive}
        setWhenToGive={setWhenToGive}
        howToOptions={howToOptions}
        whenToOptions={whenToOptions}
        addHowToOption={addHowToOption}
        addWhenToOption={addWhenToOption}
        handleDelete={handleDelete}
      />
      <Button
        variant="contained"
        style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
        startIcon={<AddIcon />}
        onClick={() => {
          setPlanTemplateCards([
            ...planTemplate.cards,
            {
              id: uuid(),
              planTemplateId: planTemplate.id,
              title: "",
              description: "",
              imageUrl: "",
              index:
                planTemplate.cards.length > 0
                  ? planTemplate.cards[planTemplate.cards.length - 1].index + 1
                  : 1,
            },
          ]);
        }}
      >
        Add Card
      </Button>
      {planTemplate.cards.map((card, index) => (
        <TemplateCardForm
          key={card.id}
          card_id={card.id}
          index={card.index}
          setIndex={(newIndex: number) =>
            updateCard(
              planTemplate.cards,
              setPlanTemplateCards,
              index,
              "index",
              newIndex
            )
          }
          title={card.title}
          setTitle={(newTitle: string) =>
            updateCard(
              planTemplate.cards,
              setPlanTemplateCards,
              index,
              "title",
              newTitle
            )
          }
          description={card.description}
          setDescription={(newDescription: string) =>
            updateCard(
              planTemplate.cards,
              setPlanTemplateCards,
              index,
              "description",
              newDescription
            )
          }
          imageUrl={card.imageUrl}
          setImageUrl={(newImageUrl: string) =>
            updateCard(
              planTemplate.cards,
              setPlanTemplateCards,
              index,
              "imageUrl",
              newImageUrl
            )
          }
          handleDelete={() =>
            deleteCard(
              planTemplate.cards,
              setPlanTemplateCards,
              deletePlanTemplateCard,
              showNotification,
              index
            )
          }
        />
      ))}
    </Grid>
  );
}

const deleteCard = (
  planTemplateCards: PlanTemplateCard[],
  setPlanTemplateCards: (cards: PlanTemplateCard[]) => void,
  deletePlanTemplateCard: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
  showNotification: (notification: NotificationData) => void,
  index: number
) => {
  deletePlanTemplateCard({
    variables: { input: { planTemplateCard: planTemplateCards[index] } },
  })
    .then(() => {
      setPlanTemplateCards([
        ...planTemplateCards.slice(0, index),
        ...planTemplateCards.slice(index + 1),
      ]);
      showNotification({
        message: "Card was removed from item.",
        severity: "success",
      });
    })
    .catch((e: any) => {
      showNotification({
        message: `Deleting card failed! ${e}`,
        severity: "error",
      });
    });
};

const updateCard = (
  planTemplateCards: PlanTemplateCard[],
  setPlanTemplateCards: (cards: PlanTemplateCard[]) => void,
  index: number,
  field: string,
  value: string | number
) => {
  setPlanTemplateCards([
    ...planTemplateCards.slice(0, index),
    { ...planTemplateCards[index], [field]: value },
    ...planTemplateCards.slice(index + 1),
  ]);
};

const PLAN_TEMPLATE_CARD_QUERY = gql`
  query PlanTemplateCardQuery($planTemplateId: ID!) {
    planTemplateCards(planTemplateId: $planTemplateId) {
      planTemplateId
      planTemplateCards {
        id
        planTemplateId
        title
        description
        imageUrl
        index
      }
    }
  }
`;

const DELETE_PLAN_TEMPLATE_CARD_MUTATION = gql`
  mutation DeletePlanTemplateCardMutation(
    $input: DeletePlanTemplateCardInput!
  ) {
    deletePlanTemplateCard(input: $input) {
      success
    }
  }
`;
