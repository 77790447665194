import { useParams } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import Photos from "./Photos";
import Highlights from "./Highlights";
import Activities from "./Activities";
import Overview from "./Overview";
import Schedule from "./Schedule";
import DataTabs from "../../../components/DataTabs";
import Questions from "./Questions";
import Reviews from "./Reviews";
import FAQs from "./FAQs";
import PlanTemplates from "./Templates";
import PendingEdits from "./PartnerEdits";

const TabIndices: Record<string, number> = {
  basicInfo: 0,
  photos: 1,
  highlights: 2,
  activities: 3,
  questions: 4,
  reviews: 5,
  faqs: 6,
  templates: 7,
  schedule: 8,
  partnerEdits: 9,
  overview: 10,
};

export default function EditPlan() {
  const { tab } = useParams<{ tab: string }>();
  const tabIndex = TabIndices[tab!] || 0;

  return (
    <DataTabs
      selectedTab={tabIndex}
      tabs={[
        {
          title: "Basic info",
          content: <BasicInfo />,
        },
        {
          title: "Photos",
          content: <Photos />,
        },
        {
          title: "Highlights",
          content: <Highlights />,
        },
        {
          title: "Activities",
          content: <Activities />,
        },
        {
          title: "Questions",
          content: <Questions />,
        },
        {
          title: "Reviews",
          content: <Reviews />,
        },
        {
          title: "FAQs",
          content: <FAQs />,
        },
        {
          title: "Items",
          content: <PlanTemplates />,
        },
        {
          title: "Schedule",
          content: <Schedule />,
        },
        {
          title: "Partner Edits",
          content: <PendingEdits />,
        },
        {
          title: "Overview",
          content: <Overview />,
        },
      ]}
    />
  );
}
