import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Button, Grid, Theme } from "@mui/material";
import BasicForm from "./BasicForm";
import ContractForm from "./ContractForm";
import OffsetForm from "./OffsetForm";
import QuestionsForm from "./QuestionsForm";
import PriceTypesForm from "./PriceTypesForm";
import SeatOptionsForm from "./SeatOptionsForm";
import { useNotifications } from "../../../../../components/Notification";
import PageSection from "../../../../../components/PageSection";
import { UnitType } from "../../../../../types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: theme.spacing(2),
  },
}));

// TODO - Export also gql fragment
type Activity = {
  id?: string;
  name?: string;
  courseMenu?: string;
  venueId?: string;
  questionsByVenue?: string[];
  offset?: {
    minutes?: number;
    hours?: number;
  };
  priceTypes?: {
    id: string;
    name?: string;
    amount?: number;
    unitType?: UnitType;
    contractedPrice?: number;
    currencyCode?: string;
    minAttendees?: number;
    maxAttendees?: number;
  }[];
  seatOptions?: {
    id?: string;
    title?: string;
    details?: string;
    minAttendees?: number;
    maxAttendees?: number;
    active?: boolean;
    photos: { src: string }[];
    price?: number;
  }[];
  contract?: {
    commissionRate?: number;
  };
};

type ActivityFormProps = {
  planId: string;
  refetch: () => void;
};

export default function EditActivityForm(props: ActivityFormProps) {
  const { showNotification } = useNotifications();
  const classes = useStyles();

  const [activity, setActivity] = useState<Activity>();

  const [addNewActivity] = useMutation(ADD_NEW_ACTIVITY_MUTATION);

  const handleChange = (value: Activity) => {
    setActivity({
      ...activity,
      ...value,
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={6}>
        <div className={classes.box}>
          <BasicForm
            activity={activity}
            onChange={handleChange}
            planId={props.planId}
          />
        </div>
        <div className={classes.box}>
          <QuestionsForm activity={activity} onChange={handleChange} />
        </div>
      </Grid>
      <Grid item lg={6}>
        <div className={classes.box}>
          <OffsetForm activity={activity} onChange={handleChange} />
        </div>

        <div className={classes.box}>
          <ContractForm activity={activity} onChange={handleChange} />
        </div>

        <div className={classes.box}>
          <PriceTypesForm activity={activity} onChange={handleChange} />
        </div>
      </Grid>

      <Grid item lg={12}>
        <SeatOptionsForm
          activity={activity}
          planId={props.planId}
          onChange={handleChange}
        />
      </Grid>
      <PageSection lg={12}>
        <Button
          variant="outlined"
          onClick={() => {
            addNewActivity({
              variables: {
                input: {
                  planId: props.planId,
                  activity,
                },
              },
            })
              .then(() => {
                showNotification({
                  message: `${
                    activity?.name || "Activity"
                  } saved successfully `,
                  severity: "success",
                });
                props.refetch();
              })
              .catch((err: any) => {
                let message: string = `Could not save form. ${err.message}`;
                if (err.networkError)
                  message = `Could not save form. Please make sure to fill all required fields, ${
                    err.networkError.result.errors[0].extensions.code
                  } - ${err.networkError.result.errors[0].message.substring(
                    0,
                    501
                  )}`;
                showNotification({
                  message,
                  severity: "error",
                });
              });
          }}
        >
          Save
        </Button>
      </PageSection>
    </Grid>
  );
}

const ADD_NEW_ACTIVITY_MUTATION = gql`
  mutation AddNewActivityMutation($input: AddNewActivityInput!) {
    addNewActivity(input: $input) {
      planId
    }
  }
`;
