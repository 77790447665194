import Grid from "@mui/material/Grid";

import Item, { ItemType } from "./Item";

interface ItemsProps {
  items: ItemType[];
  setItems: React.Dispatch<React.SetStateAction<ItemType[]>>;
  readOnly?: boolean;
}

function Items({ items, setItems, readOnly }: ItemsProps) {
  return (
    <Grid container spacing={3}>
      {items.map((item: ItemType, index: number) => (
        <Grid key={index} item xs={12} md={6}>
          <Item
            data={item}
            setQuantity={setQuantity(setItems, index)}
            readOnly={readOnly}
          />
        </Grid>
      ))}
    </Grid>
  );
}

const setQuantity = (
  setItems: React.Dispatch<React.SetStateAction<ItemType[]>>,
  index: number
) => {
  return (quantity: number) =>
    setItems((items) => {
      const adjustedItems = [...items];
      adjustedItems[index] = {
        ...adjustedItems[index],
        quantity: Math.max(quantity, 0),
      };
      return adjustedItems;
    });
};

export default Items;
