import { useQuery, gql, useMutation } from "@apollo/client";
import { Grid, Card, CardContent, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageSection from "../../../../components/PageSection";
import { useNotifications } from "../../../../components/Notification";
import { PlanTemplate, PlanTemplateCard } from "../../../../types";
import TemplateTable from "./templateTable";
import { default as CustomButton } from "../../../../components/Button2";
import TemplateForm from "./templateForm";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  cardContent: {
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(0),
  },
}));

export default function PlanTemplates() {
  const classes = useStyles();
  const { showNotification } = useNotifications();
  const { id: planId } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useQuery(PLAN_TEMPLATES_QUERY, {
    variables: { id: planId },
  });

  const [planTemplates, setPlanTemplates] = useState<PlanTemplate[]>([]);

  useEffect(() => {
    setPlanTemplates(data?.planTemplates ?? []);
  }, [data]);

  const [
    failedRequirementsForAddingTemplate,
    setFailedRequirementsForAddingTemplate,
  ] = useState(true);

  const [savePlanTemplates] = useMutation(SAVE_PLAN_TEMPLATES_MUTATION);
  const [deletePlanTemplate] = useMutation(DELETE_PLAN_TEMPLATE_MUTATION);

  const handleDeleteTemplate = useCallback(
    (index: number) => {
      const planTemplate = planTemplates[index];
      deletePlanTemplate({
        variables: { input: { planTemplate: planTemplate } },
      })
        .then(() => {
          showNotification({
            message: "Item was removed from plan.",
            severity: "success",
          });
        })
        .catch((e: any) => {
          showNotification({
            message: `Deleting item failed! ${e}`,
            severity: "error",
          });
        });

      setPlanTemplates([
        ...planTemplates.slice(0, index),
        ...planTemplates.slice(index + 1),
      ]);
    },
    [planTemplates, deletePlanTemplate, showNotification]
  );

  useEffect(() => {
    // only refetch made sure to get the correct payment status if it had just been updated
    refetch();
    if (data?.plan) {
      const { onSitePayment, amazonPay, stripe } = data.plan;

      setFailedRequirementsForAddingTemplate(
        onSitePayment && !amazonPay && !stripe
      );
    }
  }, [data?.plan, refetch]);

  const {
    loading: giftingOptionsLoading,
    error: giftingOptionsError,
    data: giftingOptionsData,
  } = useQuery(GET_GIFTING_DEFAULTS);

  const [howToOptions, setHowToOptions] = useState<string[]>([]);
  const [whenToOptions, setWhenToOptions] = useState<string[]>([]);

  useEffect(() => {
    setHowToOptions(giftingOptionsData?.giftingDefaults.howToGive ?? []);
    setWhenToOptions(giftingOptionsData?.giftingDefaults.whenToGive ?? []);
  }, [giftingOptionsData, giftingOptionsLoading, giftingOptionsError]);

  const [saveDefaults] = useMutation(SAVE_GIFTING_DEFAULTS_MUTATION, {
    update(cache) {
      const { giftingDefaults }: any = cache.readQuery({
        query: GET_GIFTING_DEFAULTS,
      });

      cache.writeQuery({
        query: GET_GIFTING_DEFAULTS,
        data: {
          giftingDefaults: {
            ...giftingDefaults,
            howToGive: howToOptions,
            whenToGive: whenToOptions,
          },
        },
      });
    },
  });

  if (loading || giftingOptionsLoading) return <>Loading...</>;
  if (error || giftingOptionsError) return <>Failed to load plan items</>;

  function handleSavePlanTemplates(planTemplates: PlanTemplate[]) {
    const invalidInput = planTemplates.some((element) =>
      Object.values(element).some((val) => val === null || val === "")
    );
    if (invalidInput) {
      showNotification({
        message: `All items require "when to give the item" and "how to give the item" input before saving.`,
        severity: "error",
      });
      return;
    }
    // saveDefaults must happen before saveTemplate
    // to ensure the tag exists in the db that template wants to reference
    saveDefaults({
      variables: {
        input: {
          howToGive: planTemplates.map((template) => template.howToGive),
          whenToGive: planTemplates.map((template) => template.whenToGive),
        },
      },
    })
      .then(() =>
        savePlanTemplates({
          variables: { input: { planTemplates } },
        })
      )
      .then(() => {
        showNotification({
          message: "Plan items and cards have been saved successfully",
          severity: "success",
        });
      })
      .catch((e: any) => {
        showNotification({
          message: `Saving plan items failed! ${e}`,
          severity: "error",
        });
      });
  }

  return (
    <Grid container>
      <Grid item lg={12}>
        <PageSection>
          <Typography variant="h6">Items assigned to this plan</Typography>
          <CustomButton
            disabled={planTemplates.length === 0}
            style={{ marginTop: "5px", marginBottom: "10px" }}
            variant="contained"
            onClick={() => {
              handleSavePlanTemplates(planTemplates);
            }}
          >
            Save Items and cards
          </CustomButton>
          <Card>
            <CardContent className={classes.cardContent}>
              {planTemplates.map((planTemplate, index) => (
                <React.Fragment key={planTemplate.id}>
                  {index !== 0 && (
                    <div
                      style={{
                        background: "#dddddd",
                        width: "100%",
                        height: "4px",
                        marginTop: "12px",
                        marginBottom: "4px",
                        borderRadius: "2px",
                      }}
                    />
                  )}

                  <TemplateForm
                    planTemplate={planTemplate}
                    setPlanTemplateCards={(cards: PlanTemplateCard[]) => {
                      setPlanTemplates([
                        ...planTemplates.slice(0, index),
                        { ...planTemplates[index], cards },
                        ...planTemplates.slice(index + 1),
                      ]);
                    }}
                    setHowToGive={(howToGive: string) => {
                      setPlanTemplates([
                        ...planTemplates.slice(0, index),
                        { ...planTemplates[index], howToGive },
                        ...planTemplates.slice(index + 1),
                      ]);
                    }}
                    setWhenToGive={(whenToGive: string) => {
                      setPlanTemplates([
                        ...planTemplates.slice(0, index),
                        { ...planTemplates[index], whenToGive },
                        ...planTemplates.slice(index + 1),
                      ]);
                    }}
                    howToOptions={howToOptions}
                    whenToOptions={whenToOptions}
                    addHowToOption={(newOption: string) =>
                      setHowToOptions((howToOptions: any) => [
                        ...howToOptions,
                        newOption,
                      ])
                    }
                    addWhenToOption={(newOption: string) =>
                      setWhenToOptions((whenToOptions: any) => [
                        ...whenToOptions,
                        newOption,
                      ])
                    }
                    handleDelete={() => handleDeleteTemplate(index)}
                  />
                </React.Fragment>
              ))}
            </CardContent>
          </Card>
        </PageSection>
        <PageSection>
          <Typography variant="h6">Add Items</Typography>
          <TemplateTable
            planId={planId!}
            failedValidation={failedRequirementsForAddingTemplate}
            onAdd={setPlanTemplates as any}
            assignedTemplates={planTemplates}
          />
        </PageSection>
      </Grid>
    </Grid>
  );
}

const PLAN_TEMPLATES_QUERY = gql`
  query PlanTemplateQuery($id: ID!) {
    planTemplates(planId: $id) {
      id
      planId
      templateId
      howToGive
      whenToGive
      template {
        id
        name
        giftmallUrl
        displayName
        overrideDisplayName
        description
      }
      cards {
        planTemplateId
        id
        index
        title
        description
        imageUrl
      }
    }
    plan(id: $id) {
      id
      onSitePayment
      stripe
      amazonPay
      activities {
        name
        venue {
          id
          name
          nameFurigana
          postcode
        }
      }
    }
  }
`;

const SAVE_PLAN_TEMPLATES_MUTATION = gql`
  mutation SavePlanTemplatesMutation($input: SavePlanTemplatesInput!) {
    savePlanTemplates(input: $input) {
      success
    }
  }
`;

const DELETE_PLAN_TEMPLATE_MUTATION = gql`
  mutation DeletePlanTemplateMutation($input: DeletePlanTemplateInput!) {
    deletePlanTemplate(input: $input) {
      success
    }
  }
`;

const GET_GIFTING_DEFAULTS = gql`
  {
    giftingDefaults {
      howToGive
      whenToGive
    }
  }
`;

const SAVE_GIFTING_DEFAULTS_MUTATION = gql`
  mutation AddGiftingDefaultsMutation($input: AddGiftingDefaultsInput) {
    addGiftingDefaults(input: $input) {
      success
      error
    }
  }
`;
