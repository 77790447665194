import { TextField } from "@mui/material";
import s from "./renderers.module.css";

export function TextRenderer({
  text,
  setText = () => {},
  label,
  mutable,
}: TextRendererProps) {
  return (
    <TextField
      fullWidth
      multiline
      value={text}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
      }}
      label={label}
      InputProps={{
        readOnly: !Boolean(setText),
      }}
      variant="outlined"
      disabled={!mutable}
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "black",
        },
        "& .MuiFormLabel-root.Mui-disabled": {
          color: "black",
        },
      }}
    />
  );
}

type TextRendererProps = {
  mutable: boolean;
  text: string;
  setText?: (text: string) => void;
  label?: string;
};

export function TripleElementRenderer({
  sideElement,
  topElement,
  bottomElement,
}: TripleElementRendererProps) {
  return (
    <div className={s.TripleElementCard}>
      <div className={s.LeftElement}>{sideElement}</div>
      <div className={s.RightElements}>
        {topElement}
        {bottomElement}
      </div>
    </div>
  );
}

type TripleElementRendererProps = {
  sideElement: JSX.Element;
  topElement: JSX.Element;
  bottomElement: JSX.Element;
};

export function TripleElementWithMediaRenderer({
  mediaUrl,
  topText,
  setTopText,
  topLabel,
  bottomText,
  setBottomText,
  bottomLabel,
  mutable,
  mediaType = "photo",
}: TripleElementWithMediaRendererProps) {
  return (
    <TripleElementRenderer
      topElement={
        <TextRenderer
          text={topText}
          setText={setTopText}
          label={topLabel}
          mutable={mutable}
        />
      }
      bottomElement={
        <TextRenderer
          text={bottomText}
          setText={setBottomText}
          label={bottomLabel}
          mutable={mutable}
        />
      }
      sideElement={<MediaRenderer mediaUrl={mediaUrl} mediaType={mediaType} />}
    />
  );
}

type TripleElementWithMediaRendererProps = {
  mediaUrl: string;
  topText: string;
  setTopText: (value: string) => void;
  topLabel: string;
  bottomText: string;
  setBottomText: (value: string) => void;
  bottomLabel: string;
  mutable: boolean;
  mediaType?: "photo" | "video";
};

export function MediaRenderer({
  mediaUrl,
  mediaType = "photo",
  style,
}: ImageRendererProps) {
  const Media = mediaType === "video" ? "video" : "img";

  return mediaUrl ? (
    <a
      href={mediaUrl}
      target="_blank"
      rel="noreferrer"
      style={{ display: "contents", ...style }}
    >
      <Media src={mediaUrl} style={{ maxWidth: "100%", maxHeight: "100%" }} />
    </a>
  ) : (
    <div>no media</div>
  );
}

type ImageRendererProps = {
  mediaUrl: string;
  mediaType?: "photo" | "video";
  style?: { [key: string]: string };
};
