import { Theme, Typography } from "@mui/material";
import { Activity } from "../../../types";
import PageSection from "../../../components/PageSection";
import { CloudinaryImage } from "../../../components/Cloudinary";

import { makeStyles } from "@mui/styles";
import Venue from "./Venue";

const useStyles = makeStyles((theme: Theme) => ({
  itemList: {
    listStyle: "none",
    paddingLeft: theme.spacing(2),
  },
  horizontalItems: {},
}));

export default function ActivityOverview(props: { activity: Activity }) {
  const classes = useStyles();
  const { activity } = props;
  return (
    <PageSection lg={12}>
      <Typography variant="h6">{activity.name}</Typography>
      {activity.venue && (
        <p>
          Venue: <br />
          <a href={`/venues/${activity.venue.id}`}>{activity.venue.name}</a>
        </p>
      )}
      <p>
        Course menu:
        <br />
        {activity.courseMenu}
      </p>

      <p>
        Details for venue:
        <br />
        {activity.detailsForVenue}
      </p>
      {activity.contract && (
        <p>
          Contract:
          <br />
          Commission rate: {activity.contract.commissionRate}
        </p>
      )}
      {activity.priceTypes && (
        <p>
          Price types:
          <ul className={classes.itemList}>
            {activity.priceTypes.map((price) => (
              <li className={classes.horizontalItems}>
                <Typography variant="h6">{price.name}</Typography>
                <ul className={classes.itemList}>
                  <li>Amount: {price.amount}</li>
                  <li>Unit type: {price.unitType}</li>
                  <li>Contracted amount: {price.contractedPrice}</li>
                  <li>Currency code: {price.currencyCode}</li>
                  <li>Min. attendees: {price.minAttendees}</li>
                  <li>Max. attendees: {price.maxAttendees}</li>
                </ul>
              </li>
            ))}
          </ul>
        </p>
      )}
      {activity.seatOptions && (
        <p>
          Seat options:
          <ul className={classes.itemList}>
            {activity.seatOptions.map((seatOption) => (
              <li>
                <Typography variant="h6">{seatOption.title}</Typography>
                <ul className={classes.itemList}>
                  <li>Details: {seatOption.details}</li>
                  <li>
                    Attendees: {seatOption.minAttendees}-
                    {seatOption.maxAttendees}
                  </li>
                  {seatOption.price && <li>Price: {seatOption.price}</li>}
                  <li>
                    {seatOption.photos.map((p) => (
                      <CloudinaryImage
                        key={p.src}
                        src={p.src}
                        width={120}
                        style={{ margin: 3 }}
                        transformations={["w_120"]}
                      />
                    ))}
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </p>
      )}
      {activity.venue && <Venue venue={activity.venue} />}
    </PageSection>
  );
}
