import { gql, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useNotifications } from "../../../../../components/Notification";

export default function UnpublishPlanDialog(props: {
  activity: { id?: string; name?: string };
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const { showNotification } = useNotifications();
  const [deleteActivityMutation] = useMutation(DELETE_ACTIVITY_MUTATION);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Delete <b>{props.activity.name}</b>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete the activity?
        <br />
        <br />
        This action will remove the activity from the plan.
        <br />
        <br />
        Reservations for the activities will stay in the DB.
        <br />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          No
        </Button>
        <Button color="primary" onClick={deleteActivity}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  function deleteActivity() {
    deleteActivityMutation({
      variables: {
        input: {
          id: props.activity.id,
        },
      },
    })
      .then(() => {
        showNotification({
          message: `The activity ${props.activity.name} was successfully deleted.`,
          severity: "success",
        });
        props.onConfirm();
      })
      .catch((reason: Error) => {
        showNotification({
          message: `Unpublish plan failed! ${reason.message}`,
          severity: "error",
        });
      });

    props.onClose();
  }
}

const DELETE_ACTIVITY_MUTATION = gql`
  mutation DeleteActivityMutation($input: DeleteActivityInput!) {
    deleteActivity(input: $input) {
      activity {
        id
      }
    }
  }
`;
