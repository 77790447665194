import { useQuery, gql, ApolloQueryResult } from "@apollo/client";

import DataTabs from "../../../../components/DataTabs";
import ActivityReservation from "./ActivityReservation";
import { ActivityReservationType } from "../ActionButtons/types";

export default function Activities(props: {
  bookingId: string;
  paymentStatus: string;
  paymentMethod: string;
  refetchBooking: () => Promise<ApolloQueryResult<any>>;
}) {
  const { loading, error, data, refetch: refetchActivity } = useQuery(
    GET_BOOKING_ACTIVITY_RESERVATIONS,
    {
      variables: { bookingId: props.bookingId },
    }
  );

  if (loading) return <>loading...</>;
  if (error) return <>something went wrong</>;
  const activityReservations = data.bookingActivityReservations.records;

  return (
    <DataTabs
      tabs={activityReservations.map(
        (activityReservation: ActivityReservationType, index: number) => ({
          title: `${index + 1}: ${activityReservation.venue.name}`,
          content: (
            <ActivityReservation
              bookingId={props.bookingId}
              data={activityReservations[index]}
              refetch={() => refetch(props.refetchBooking, refetchActivity)}
              paymentStatus={props.paymentStatus}
              canEditItems={props.paymentMethod === "ON_SITE_PAYMENT"}
            />
          ),
        })
      )}
    />
  );
}

async function refetch(
  refetchBooking: () => Promise<ApolloQueryResult<any>>,
  refetchActivity: () => Promise<ApolloQueryResult<any>>
) {
  await refetchBooking();
  await refetchActivity();
}

export const GET_BOOKING_ACTIVITY_RESERVATIONS = gql`
  query BookingActivityReservationsQuery($bookingId: ID!) {
    bookingActivityReservations(bookingId: $bookingId) {
      totalCount
      records {
        id
        status
        cancellationPolicyDescription
        activity {
          id
          contract {
            commissionRate
          }
        }
        venue {
          id
          name
        }
        seatOption {
          id
          title
          price
        }
        datetime
        specialRequests
        createdAt
        reservedAt
        rejectedAt
        cancelledAt
        items {
          id
          priceType
          unitType
          quantity
          contractedPrice
        }
        answers {
          question
          answer
        }
      }
    }
  }
`;
