import { Card, CardHeader, CardContent, Theme, Paper } from "@mui/material";
import VenueSelection from "../../../../../components/VenueSelection";
import ImageGalleryUploader from "../../../../../components/ImageGalleryUploader";
import TextField from "../../../../../components/TextField";
import { v4 as uuid } from "uuid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

interface Activity {
  id?: string;
  name?: string;
  courseMenu?: string;
  coursePhotos?: {
    activityId: string;
    id: string;
    src: string;
    photoIndex: number;
  }[];
  detailsForVenue?: string;
  venueId?: string;
}

type ActivityFormProps = {
  activity?: Activity;
  onChange: (activity: Activity) => void;
  planId?: string;
};

export default function ActivityForm(props: ActivityFormProps) {
  const classes = useStyles();

  const { planId, activity, onChange } = props;
  const isNewActivity = activity?.id === undefined;
  const { id, name, courseMenu, venueId, detailsForVenue, coursePhotos } =
    activity || {};
  return (
    <Card>
      <CardHeader title="Basic Information" className={classes.cardTitle} />
      <CardContent>
        <TextField
          required
          multiline
          label="Name"
          value={name}
          onChange={(e: any) => {
            onChange({ name: e.target.value });
          }}
        />

        <VenueSelection
          required
          disabled={!isNewActivity}
          onVenueSelected={(venue) => {
            onChange({ venueId: venue?.id });
          }}
          venueId={venueId}
        />

        <TextField
          multiline
          label="Details for venue"
          value={detailsForVenue || ""}
          onChange={(e: any) => {
            onChange({ detailsForVenue: e.target.value });
          }}
        />

        <TextField
          multiline
          label="Course menu"
          value={courseMenu || ""}
          onChange={(e: any) => {
            onChange({ courseMenu: e.target.value });
          }}
        />

        {id ? (
          <div style={{ marginBottom: 10, marginTop: 20 }}>
            <strong style={{ marginTop: 10 }}>Course Photos</strong>
            <Paper
              elevation={0}
              style={{
                width: "auto",
                height: "auto",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ImageGalleryUploader
                images={
                  coursePhotos
                    ? coursePhotos.map((cp) => ({
                        id: cp.id,
                        imageUrl: cp.src,
                      }))
                    : []
                }
                uploadPath={`plans/${planId}/activity/${id}/course`}
                allowDnd={false}
                gridSizes={{ xs: 12, md: 4 }}
                onChangeFunction={(coursePhotos) => {
                  onChange({
                    coursePhotos: coursePhotos.map((p, index: number) => {
                      return {
                        activityId: id,
                        id: p.id ? p.id : uuid(),
                        photoIndex: index,
                        src: p.imageUrl,
                      };
                    }),
                  });
                }}
              />
            </Paper>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
}
