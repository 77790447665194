import { Grid, Card, CardHeader, CardContent, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuid } from "uuid";
import { UnitType } from "../../../../../types";
import TextField from "../../../../../components/TextField";
import Select from "../../../../../components/Select";
import Checkbox from "../../../../../components/Checkbox";

type PriceType = {
  id: string;
  name?: string;
  unitType?: UnitType;
  amount?: number;
  contractedPrice?: number;
  currencyCode?: string;
  minAttendees?: number;
  maxAttendees?: number;
  index?: number;
  active?: boolean;
};

type Activity = {
  priceTypes?: PriceType[];
};

type PriceTypeFormProps = {
  activity?: Activity;
  onChange: (activity: Activity) => void;
};

const numberOfUndefined = (val: string) => {
  const number = Number(val);

  if (number === 0) {
    return 0;
  }

  return number || undefined;
};

export default function PriceTypeForm(props: PriceTypeFormProps) {
  const { activity, onChange } = props;
  const priceTypes = activity?.priceTypes || [];

  function setPriceTypeField<T>(
    fieldName: keyof PriceType,
    index: number,
    value: T
  ) {
    const copy = [...priceTypes];
    const toBeChanged = priceTypes[index];
    const newValue: any = {
      ...toBeChanged,
      [fieldName]: value,
    };

    copy[index] = newValue;
    onChange({ priceTypes: copy });
  }

  return (
    <Card>
      <CardHeader
        title="Price types"
        action={
          <IconButton
            onClick={() => {
              onChange({
                priceTypes: priceTypes.concat({
                  id: uuid(),
                  currencyCode: "JPY",
                }),
              });
            }}
          >
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        <div
          style={{
            marginTop: "-20px",
            marginBottom: "-4px",
            color: "green",
            fontSize: "14px",
          }}
        >
          * The Price type with <strong>index 1</strong> will display in
          PlanCard and other UI.
        </div>
        <Grid container spacing={1}>
          {priceTypes.map((p, index) => (
            <Grid key={index} item lg={6}>
              <div style={{ textAlign: "right" }}>
                <IconButton
                  onClick={() =>
                    onChange({
                      priceTypes: priceTypes.filter(
                        (priceType) => priceType.id !== p.id
                      ),
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </div>

              <Checkbox
                label="Active"
                labelPlacement="end"
                checked={p.active ? p.active : false}
                onChange={(checked) =>
                  setPriceTypeField("active", index, checked)
                }
              />

              <TextField
                required
                id={`${p.id}_name`}
                label="Name"
                value={p.name}
                onChange={(e: any) => {
                  setPriceTypeField("name", index, e.target.value);
                }}
                inputProps={{
                  maxLength: 40,
                }}
              />

              <Select
                required={true}
                style={{ marginTop: "10px" }}
                label="Unit type"
                value={p.unitType}
                options={Object.entries(UnitType).map(([key, value]) => {
                  return {
                    id: key,
                    name: value,
                  };
                })}
                onChange={(option: string) => {
                  setPriceTypeField("unitType", index, option);
                }}
              />

              <TextField
                required
                id={`${p.id}_amount`}
                label="Amount"
                value={p.amount}
                onChange={(e: any) => {
                  setPriceTypeField(
                    "amount",
                    index,
                    numberOfUndefined(e.target.value)
                  );
                }}
                startAdornment={"¥"}
              />

              <TextField
                required
                id={`${p.id}_contracted`}
                label="Contracted amount"
                value={p.contractedPrice}
                onChange={(e: any) => {
                  setPriceTypeField(
                    "contractedPrice",
                    index,
                    numberOfUndefined(e.target.value)
                  );
                }}
                startAdornment={"¥"}
              />

              <TextField
                required
                id={`${p.id}_min_attendees`}
                label="Min. attendees"
                value={p.minAttendees}
                onChange={(e: any) => {
                  setPriceTypeField(
                    "minAttendees",
                    index,
                    numberOfUndefined(e.target.value)
                  );
                }}
              />

              <TextField
                id={`${p.id}_max_attendees`}
                label="Max. attendees"
                value={p.maxAttendees || ""}
                onChange={(e: any) => {
                  setPriceTypeField(
                    "maxAttendees",
                    index,
                    numberOfUndefined(e.target.value)
                  );
                }}
              />

              <TextField
                required
                id={`${p.id}_index`}
                label="Index"
                value={p.index}
                onChange={(e: any) => {
                  setPriceTypeField(
                    "index",
                    index,
                    numberOfUndefined(e.target.value)
                  );
                }}
              />
              {!p.index && (
                <div
                  style={{ marginTop: "10px", color: "red", fontSize: "14px" }}
                >
                  * Price types must have required property 'index'
                </div>
              )}
              {p.index === 1 && (
                <div
                  style={{
                    marginTop: "10px",
                    color: "green",
                    fontSize: "14px",
                  }}
                >
                  * This Price will display in PlanCard and other UI.
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
