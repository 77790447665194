import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { EditStatus, FieldName, PartnerPlanEdit } from "../../../../../types";
import basicInfoCard from "./content/BasicInfoTextCard";
import faqCard from "./content/FaqCard";
import highlightCard from "./content/HighlightCard";
import inclusionCard from "./content/InclusionCard";
import { Header } from "./components/Header";
import s from "./Card.module.css";
import courseMenuCard from "./content/CourseMenuCard";
import coursePhotoCard from "./content/CoursePhotoCard";

export const getCard = (fieldName: FieldName) => {
  switch (fieldName) {
    case FieldName.NAME:
    case FieldName.REMARKS:
    case FieldName.SUBTITLE:
    case FieldName.DESCRIPTION:
      return basicInfoCard;
    case FieldName.COURSEMENU:
      return courseMenuCard;
    case FieldName.COURSEPHOTOS:
      return coursePhotoCard;
    case FieldName.FAQS:
      return faqCard;
    case FieldName.HIGHLIGHTS:
      return highlightCard;
    case FieldName.NOTINCLUDEDINCLUSIONS:
    case FieldName.GIFTINCLUSIONS:
    case FieldName.MEALINCLUSIONS:
    case FieldName.SEATINCLUSIONS:
    case FieldName.EXPERIENCEINCLUSIONS:
      return inclusionCard;
  }
};

export default function BaseCard({
  edit,
  headerContent,
  contentRef,
  originalValue,
  ...props
}: BaseCardProps) {
  const newValue = JSON.parse(edit.newValue);
  const fieldName = edit.fieldName;
  const editStatus = edit.editStatus;

  const { Content } = getCard(fieldName);

  return (
    <>
      <Card>
        <CardHeader
          component={() => (
            <div className={s[edit.editStatus]}>
              <Header
                status={readableEditStatus[edit.editStatus]}
                fieldName={readableFieldName[edit.fieldName]}
                date={edit.createdAt}
              >
                {headerContent}
              </Header>
            </div>
          )}
        />
        <Divider />
        <CardContent>
          {editStatus !== EditStatus.ADDED && (
            //   if this is not a new field, show the original value
            <>
              {editStatus === EditStatus.TOUCHED && (
                <Typography variant="h6">Original</Typography>
              )}

              <div className={s.NoInteraction}>
                {
                  <Content
                    ref={editStatus === EditStatus.REMOVED ? contentRef : null}
                    mutable={false}
                    {...originalValue}
                    {...(props.mutable && props.mutationProps)}
                  />
                }
              </div>
            </>
          )}

          {editStatus === EditStatus.TOUCHED && (
            // if we show both the original and the requested value, add a divier
            <Divider className={s.Divider} />
          )}

          {editStatus !== EditStatus.REMOVED && (
            // if this is not a field to delete, show the requested value
            <>
              {edit.editStatus === EditStatus.TOUCHED && (
                <Typography variant="h6">Changes</Typography>
              )}
              <Content
                ref={contentRef}
                mutable={props.mutable}
                {...newValue}
                {...(props.mutable && props.mutationProps)}
              />
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export type BaseCardProps = {
  edit: PartnerPlanEdit;
  originalValue: any;
  headerContent?: JSX.Element;
  contentRef?: any;
} & (
  | {
      mutable: true;
      mutationProps: any;
    }
  | {
      mutable: false | undefined;
    }
);

const readableEditStatus = {
  [EditStatus.ADDED]: "Addition",
  [EditStatus.REMOVED]: "Removal",
  [EditStatus.TOUCHED]: "Edit",
  [EditStatus.UNTOUCHED]: "",
};

const readableFieldName = {
  [FieldName.COURSEMENU]: "course menu",
  [FieldName.COURSEPHOTOS]: "course photo",
  [FieldName.DESCRIPTION]: "description",
  [FieldName.FAQS]: "faq",
  [FieldName.HIGHLIGHTS]: "highlight",
  [FieldName.NAME]: "name",
  [FieldName.REMARKS]: "remarks",
  [FieldName.SUBTITLE]: "subtitle",
  [FieldName.EXPERIENCEINCLUSIONS]: "experience inclusion",
  [FieldName.GIFTINCLUSIONS]: "gift inclusion",
  [FieldName.MEALINCLUSIONS]: "meal inclusion",
  [FieldName.NOTINCLUDEDINCLUSIONS]: "not included inclusion",
  [FieldName.SEATINCLUSIONS]: "seat inclusion",
};
