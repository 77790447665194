import React, { useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { useQuery, gql } from "@apollo/client";
import {
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Overview from "./Overview";
import { startOfMonth, addMonths } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "../../../components/TextField";
import { format } from "date-fns-tz";

const lastMonth = startOfMonth(addMonths(new Date(), -1));

export default function VenueCosts() {
  const navigate = useNavigate();
  const location = useLocation();
  const [yearMonth, setYearMonth] = useState<Date | null>(lastMonth);

  const rowsPerPage = 10;
  const parsedQueryString = queryString.parse(location.search);

  const [page, setPage] = React.useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = React.useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  useEffect(() => {
    const urlParams = {
      page: page + 1,
      search: search,
    };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, search, navigate]);

  const resetPagination = () => {
    setPage(0);
    setTotalRows(0);
  };

  const from = yearMonth ? format(yearMonth, "yyyy-MM-dd") : null;
  const until = yearMonth
    ? format(startOfMonth(addMonths(yearMonth, 1)), "yyyy-MM-dd")
    : null;

  const { loading, error, data } = useQuery(GET_VENUE_RESERVATIONS, {
    variables: {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search,
      from,
      until,
      hasPaidReservations: {
        from,
        until,
      },
    },
  });

  const rows = useMemo(
    () =>
      [...(data?.venues.records ?? [])].map((venue) => ({
        ...venue,
        reservations: venue.activities.flatMap((activity: any) =>
          activity.reservations.map((reservation: any) => ({
            ...reservation,
            status:
              reservation.booking.status === "CANCELLED"
                ? "CANCELLED"
                : reservation.status,
            activityName: activity.name,
            planName: activity.plan.name,
            priceTypes: activity.priceTypes,
          }))
        ),
      })),
    [data]
  );

  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (data === undefined) return;
    setTotalRows(data.venues.total);
  }, [data]);

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  if (error) return <>Error! {error.message}</>;

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div style={{ flexShrink: 0 }}>
              <DatePicker
                label="Month and Year"
                value={yearMonth}
                onChange={(event) => {
                  setYearMonth(event);
                  resetPagination();
                }}
                views={["year", "month"]}
                openTo="month"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!!totalRows && (
                <Typography style={{ whiteSpace: "nowrap" }}>
                  {page * rowsPerPage + 1} -{" "}
                  {Math.min(totalRows, (page + 1) * rowsPerPage + 1)} of{" "}
                  {totalRows}
                </Typography>
              )}
              <div style={{ display: "flex" }}>
                <IconButton
                  disabled={page === 0 || totalRows === 0}
                  onClick={() => setPage((page) => Math.max(0, page - 1))}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  disabled={page === totalPages - 1 || totalRows === 0}
                  onClick={() =>
                    setPage((page) => Math.min(totalPages - 1, page + 1))
                  }
                >
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </div>
            <TextField
              label="Search"
              style={{ flexShrink: 1 }}
              value={search ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
                resetPagination();
              }}
            />
          </div>
        </CardContent>
      </Card>
      {loading ? (
        <LinearProgress />
      ) : (
        <Overview venues={rows} date={yearMonth!} />
      )}
    </>
  );
}

const GET_VENUE_RESERVATIONS = gql`
  query VenueReservationsQuery(
    $offset: Int!
    $limit: Int!
    $from: String
    $until: String
    $search: String
    $hasPaidReservations: HasPaidReservationsInput
  ) {
    venues(
      offset: $offset
      limit: $limit
      search: $search
      hasPaidReservations: $hasPaidReservations
    ) {
      total
      records {
        name
        id
        company {
          id
          bankAccount {
            id
          }
        }
        activities {
          id
          name
          priceTypes {
            id
            name
            unitType
            amount
            currencyCode
            contractedPrice
          }
          plan {
            id
            name
          }
          reservations(
            from: $from
            until: $until
            onlyPaidReservations: true
            onlyPrepaidReservations: true
          ) {
            id
            status
            datetime
            specialRequests
            commissionRate
            venuePaymentRate
            booking {
              id
              status
              familyName
              givenName
            }
            seatOption {
              price
            }
            items {
              id
              quantity
              costCurrencyCode
              contractedPrice
              priceType {
                contractedPrice
                currencyCode
                name
                id
              }
            }
            costs {
              commissionRate
              venuePaymentRate
              contractedAmount
              commission
              venuePaymentAmount
              currencyCode
              seatPrice
            }
          }
        }
      }
    }
  }
`;
