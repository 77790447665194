import { Typography, Grid } from "@mui/material";
import { CloudinaryImage } from "../../../components/Cloudinary";
import { Venue } from "../../../types";

export default function VenueOverview(props: { venue: Venue }) {
  const { venue } = props;

  return (
    <>
      <div style={{ width: "100%" }}>
        <Typography variant="h6">Venue</Typography>
      </div>
      <Grid container justifyContent="space-between">
        <Grid item lg={6}>
          <div style={{ width: "100%" }}>
            <Typography variant="h6">Data</Typography>
          </div>
          <p>
            Description: <br />
            {venue.description}
          </p>
          <p>
            Phone number: <br />
            {venue.phoneNumber}
          </p>
          <p>
            Email: <br />
            {venue.email}
          </p>
          <p>
            Payment methods: <br />
            {venue.acceptedPaymentMethods}
          </p>
          <p>
            Number of seats: <br />
            {venue.numberOfSeats}
          </p>
          <p>
            Business hours: <br />
            {venue.businessHours}
          </p>
          <p>
            Holidays: <br />
            {venue.holidays}
          </p>
          <p>
            Smoking allowance: <br />
            {venue.smokingAllowance}
          </p>
          <p>
            Dress code: <br />
            {venue.dressCode}
          </p>
          <p>
            Limited entry for children: <br />
            {venue.limitedEntryForChildren}
          </p>
          <p>
            Baby chair: <br />
            {venue.babyChair}
          </p>
          <p>
            Kid chair: <br />
            {venue.kidChair}
          </p>
          <p>
            Breast feeding room: <br />
            {venue.breastFeedingRoom}
          </p>
          <p>
            Wheelchair access: <br />
            {venue.wheelChairAccess}
          </p>
          <p>
            Parking: <br />
            {venue.parking}
          </p>
          <p>
            Remarks: <br />
            {venue.remarks}
          </p>
        </Grid>
        <Grid item lg={5}>
          <div style={{ width: "100%" }}>
            <Typography variant="h6">Location</Typography>
          </div>

          <p>
            Address: <br />
            {venue.location.address}
          </p>
          <p>
            Google maps url: <br />
            <a
              href={venue.location.googleMapsUrl}
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
          </p>
          {venue.location.googleMapsEmbedUrl && (
            <p>
              Google maps: <br />
              <iframe
                title="google maps"
                width="400"
                height="200"
                style={{ border: 0 }}
                loading="lazy"
                src={venue.location.googleMapsEmbedUrl}
              ></iframe>
            </p>
          )}
          <p>
            Directions: <br />
            {venue.location.directions}
          </p>
          <p>
            Nearest stations: <br />
            {venue.location.nearestStations}
          </p>
          <div style={{ width: "100%" }}>
            <Typography variant="h6">Photos</Typography>
          </div>
          {venue.photos?.map((p) => (
            <CloudinaryImage
              key={p.imageUrl}
              src={p.imageUrl}
              width={120}
              style={{ margin: 3 }}
              transformations={["w_120"]}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
