import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { ButtonGroup, Button, Grid, Theme } from "@mui/material";
import BasicForm from "./BasicForm";
import ContractForm from "./ContractForm";
import OffsetForm from "./OffsetForm";
import QuestionsForm from "./QuestionsForm";
import PriceTypesForm from "./PriceTypesForm";
import SeatOptionsForm from "./SeatOptionsForm";
import { useNotifications } from "../../../../../components/Notification";
import PageSection from "../../../../../components/PageSection";
import DeleteActivityDialog from "./DeleteActivityDialog";
import { UnitType } from "../../../../../types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: theme.spacing(2),
  },
}));

const removeEmptyFields = (activity: Activity) => {
  // nested values could be cleaned recursively, but then graphql mutation is sent for each step
  // specifically remove the price of seat option if null
  activity.seatOptions?.forEach((so) => so.price ?? delete so["price"]);
  return Object.fromEntries(
    Object.entries(activity).filter(([_, v]) => v !== null && v !== "")
  ) as Activity;
};

const omitTypename = (fieldToOmit: string) => (key: string, value: any) =>
  key === fieldToOmit ? undefined : value;

const deepOmit = (
  fieldToOmit: string,
  data: Record<string, unknown>
): Record<string, unknown> => {
  return JSON.parse(JSON.stringify(data), omitTypename(fieldToOmit));
};

// TODO - Export also gql fragment
type Activity = {
  id?: string;
  name?: string;
  venueId?: string;
  courseMenu?: string;
  coursePhotos?: {
    activityId: string;
    id: string;
    src: string;
    photoIndex: number;
  }[];
  questionsByVenue?: string[];
  offset?: {
    minutes?: number;
    hours?: number;
  };
  priceTypes?: {
    id: string;
    name?: string;
    unitType?: UnitType;
    amount?: number;
    contractedPrice?: number;
    currencyCode?: string;
    minAttendees?: number;
    maxAttendees?: number;
  }[];
  seatOptions?: {
    id?: string;
    title?: string;
    details?: string;
    minAttendees?: number;
    maxAttendees?: number;
    active?: boolean;
    photos: { src: string }[];
    price?: number;
  }[];
  contract?: {
    commissionRate?: number;
  };
};

type ActivityFormProps = {
  planId: string;
  activity: Activity;
  refetch: () => void;
};

export default function EditActivityForm(props: ActivityFormProps) {
  const { showNotification } = useNotifications();
  const classes = useStyles();

  const cleanActivity: Activity = deepOmit(
    "__typename",
    props.activity
  ) as Activity;

  const [activity, setActivity] = useState<Activity>(cleanActivity);
  const [saveActivity] = useMutation(SAVE_ACTIVITY_MUTATION);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleChange = (value: Activity) => {
    setActivity({
      ...activity,
      ...value,
    });
  };

  return (
    <Grid container spacing={1}>
      <DeleteActivityDialog
        activity={props.activity}
        open={isDeleting}
        onConfirm={() => {
          props.refetch();
          setIsDeleting(false);
        }}
        onClose={() => {
          setIsDeleting(false);
        }}
      />
      <Grid item lg={6}>
        <div className={classes.box}>
          <BasicForm
            activity={activity}
            onChange={handleChange}
            planId={props.planId}
          />
        </div>
        <div className={classes.box}>
          <QuestionsForm activity={activity} onChange={handleChange} />
        </div>
      </Grid>
      <Grid item lg={6}>
        <div className={classes.box}>
          <OffsetForm activity={activity} onChange={handleChange} />
        </div>

        <div className={classes.box}>
          <ContractForm activity={activity} onChange={handleChange} />
        </div>

        <div className={classes.box}>
          <PriceTypesForm activity={activity} onChange={handleChange} />
        </div>
      </Grid>

      <Grid item lg={12}>
        <SeatOptionsForm
          activity={activity}
          planId={props.planId}
          onChange={handleChange}
        />
      </Grid>
      <PageSection lg={12}>
        <ButtonGroup>
          <Button
            variant="outlined"
            onClick={() => {
              const activeSeatOption =
                activity.seatOptions?.filter((seat) => seat.active === true) ||
                [];
              if (activeSeatOption.length === 0) {
                showNotification({
                  message: `${
                    activity.name || "Activity"
                  } requires at least 1 active seat option.`,
                  severity: "error",
                });
                return;
              }
              saveActivity({
                variables: {
                  input: removeEmptyFields(activity),
                },
              })
                .then(() => {
                  showNotification({
                    message: `${
                      activity.name || "Activity"
                    } saved successfully.`,
                    severity: "success",
                  });
                  props.refetch();
                })
                .catch((err) => {
                  let message: string = `Could not save form. ${err.message}`;
                  if (err.networkError)
                    message = `Could not save form. Please make sure to fill all required fields, ${
                      err.networkError.result.errors[0].extensions.code
                    } - ${err.networkError.result.errors[0].message.substring(
                      0,
                      501
                    )}`;
                  showNotification({
                    message,
                    severity: "error",
                  });
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setIsDeleting(true);
            }}
          >
            Delete
          </Button>
        </ButtonGroup>
      </PageSection>
    </Grid>
  );
}

const SAVE_ACTIVITY_MUTATION = gql`
  mutation SaveActivityMutation($input: SaveActivityInput!) {
    saveActivity(input: $input) {
      activity {
        id
        name
      }
    }
  }
`;
